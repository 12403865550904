export const BlogActionTypes = {
    FETCH_BLOG: "FETCH_BLOG",
    FETCH_BLOG_ERROR: "FETCH_BLOG_ERROR",
    DELETE_BLOG: "DELETE_BLOG",
    DELETE_BLOG_ERROR: "DELETE_BLOG_ERROR",
    ADD_BLOG: "ADD_BLOG",
    ADD_BLOG_ERROR: "ADD_BLOG_ERROR",
    UPDATE_BLOG: "UPDATE_BLOG",
    UPDATE_BLOG_ERROR: "UPDATE_BLOG_ERROR",
    CLEAR_ERROR: "CLEAR_ERROR",
    CLEAR_MESSAGE: "CLEAR_MESSAGE"
}