import React from 'react'
import toast from 'react-hot-toast';
import {
    Button, Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import { URL } from '../../../config/serverUrl';
import axiosInstance from '../../../config/axiosInstance';
const DeleteConfirmation = ({ modal, toggle,  serviceToDelete, setServiceToDelete, setServicesToRender, }) => {
    const handleDelete = async () => {
        // toggle()
        try {
            const result = await axiosInstance.delete(`${URL}/admin/delete-services-with-details/${serviceToDelete._id}`)
            setServicesToRender(pv => pv.filter(el => el._id !== serviceToDelete._id))
            toast.success("City Deleted successfully")
        }
        catch (error) {
            toast.success("Something went wrong")
        } finally {
            setServiceToDelete(null)
            toggle()
        }
    }
    return (
        <Modal isOpen={modal} fade={false} toggle={toggle}>
            <ModalHeader toggle={toggle}>Warning!</ModalHeader>
            <ModalBody>
                Do you want delete this city.
            </ModalBody>
            <ModalFooter>
                <Button color="danger" onClick={
                    handleDelete
                }>
                    Delete
                </Button>{' '}
                <Button color="secondary" onClick={() => {
                    setServiceToDelete(null)
                    toggle()
                }}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    )
}
export default DeleteConfirmation
