export const TestimonialsActionTypes = {
    FETCH_TESTIMONIALS: "FETCH_TESTIMONIALS",
    FETCH_TESTIMONIALS_ERROR: "FETCH_TESTIMONIALS_ERROR",
    DELETE_TESTIMONIALS: "DELETE_TESTIMONIALS",
    DELETE_TESTIMONIALS_ERROR: "DELETE_TESTIMONIALS_ERROR",
    ADD_TESTIMONIALS: "ADD_TESTIMONIALS",
    ADD_TESTIMONIALS_ERROR: "ADD_TESTIMONIALS_ERROR",
    UPDATE_TESTIMONIALS: "UPDATE_TESTIMONIALS",
    UPDATE_TESTIMONIALS_ERROR: "UPDATE_TESTIMONIALS_ERROR",
    CLEAR_MESSAGE: "CLEAR_MESSAGE"
}