import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Collapse } from "reactstrap";
import styles from "./SideNav.module.scss";
import Logo from "../../Assets/Group 942.svg";
import { ReactComponent as Dashboard } from "../../Assets/dashboard.svg";
import { ReactComponent as Booking } from "../../Assets/25-online booking.svg";
import { ReactComponent as ContentManagement } from "../../Assets/content-management.svg";
import { ReactComponent as Fleet } from "../../Assets/car.svg";
import { ReactComponent as Testimonials } from "../../Assets/XMLID_2534_.svg";
import { ReactComponent as ContactList } from "../../Assets/Contact List.svg";
import { ReactComponent as ContactDetails } from "../../Assets/Contact List.svg";
import { useDispatch, useSelector } from "react-redux";
import { URL } from "../../config/serverUrl";

import { NavLinks } from "../../utils";
import { normalizeLinkName } from "../../utils";

import axios from "axios";
import toast from "react-hot-toast";
import axiosInstance from "../../config/axiosInstance";
function SideNav({ onClick }) {
  let [isAdmin, setIsAdmin] = useState(true);
  let [loading, setLoading] = useState(false);
  let [menu, setMenu] = useState([]);
  const location = useLocation().pathname;
  const styleHandler = (link) => {
    if (location === link) {
      return styles.activeLinksContainer;
    } else {
      return styles.linksContainer;
    }
  };


  useEffect(async () => {
    setIsAdmin(true);
    setMenu([])
    if (localStorage.getItem("role") === "Staff") {
      setLoading(true)
      try {
        let res = await axiosInstance.post(`${URL}/admin/admingetstaff`);
        setMenu(res.data.data.menu);
        setIsAdmin(false);
      } catch (error) {

      }finally{
       setLoading(false);
      }


    }

    // Checking location
  }, [location]);

  const [isOpenContent, setIsOpenContent] = useState(false);

  const menuItems = !isAdmin
    ? NavLinks.filter((item) => {
        const normalizedLinkName = normalizeLinkName(item.linkName);
        return menu.includes(normalizedLinkName);
      }).map((item, index) => (
        <div key={index}>
          {item.subLinks ? (
            <div className={styles.mainDiv}>
              <div className={styles.iconContainer}>
                <ContentManagement className={styles.icon} width="100%" />
              </div>
              <div className={styles.ContentContainer}>
                <p
                  onClick={() => setIsOpenContent(!isOpenContent)}
                  className={styles.link}
                >
                  {item.linkName} <i className="fas fa-caret-down"></i>
                </p>
                <Collapse isOpen={isOpenContent}>
                  {item.subLinks.map((subLink, subIndex) => (
                    <Link
                      key={subIndex}
                      to={subLink.link}
                      className={styleHandler(subLink.link)}
                    >
                      <div className={styles.linkContainer}>
                        <p className={styles.link}>{subLink.linkName}</p>
                      </div>
                    </Link>
                  ))}
                </Collapse>
              </div>
            </div>
          ) : (
            <Link to={item.link} className={styleHandler(item.link)}>
              <div className={styles.iconContainer}>
                <Dashboard className={styles.icon} width="100%" />
              </div>
              <div className={styles.linkContainer}>
                <p className={styles.link}>{item.linkName}</p>
              </div>
            </Link>
          )}
        </div>
      ))
    : NavLinks.map((item, index) => (
        <div key={index}>
          {item.subLinks ? (
            <div className={styles.mainDiv}>
              <div className={styles.iconContainer}>
                <ContentManagement className={styles.icon} width="100%" />
              </div>
              <div className={styles.ContentContainer}>
                <p
                  onClick={() => setIsOpenContent(!isOpenContent)}
                  className={styles.link}
                >
                  {item.linkName} <i className="fas fa-caret-down"></i>
                </p>
                <Collapse isOpen={isOpenContent}>
                  {item.subLinks.map((subLink, subIndex) => (
                    <Link
                      key={subIndex}
                      to={subLink.link}
                      className={styleHandler(subLink.link)}
                    >
                      <div className={styles.linkContainer}>
                        <p className={styles.link}>{subLink.linkName}</p>
                      </div>
                    </Link>
                  ))}
                </Collapse>
              </div>
            </div>
          ) : (
            <Link to={item.link} className={styleHandler(item.link)}>
              <div className={styles.iconContainer}>
                <Dashboard className={styles.icon} width="100%" />
              </div>
              <div className={styles.linkContainer}>
                <p className={styles.link}>{item.linkName}</p>
              </div>
            </Link>
          )}
        </div>
      ));
      console.log(menuItems);

  return (
    <div
      style={location === "/login" ? { display: "none" } : { display: "block" }}
    >
      <div className={styles.mainContainer}>
        <div className={styles.logoContainer}>
          <img src={Logo} alt="logo" width="100%" />
          <i class="fas fa-times" onClick={onClick}></i>
        </div>

        <div className={styles.menuContainer}>
          {!isAdmin && loading && "Loading..."}
          {isAdmin && !loading && menuItems}
          {!isAdmin && !loading && menu.length && menuItems}
          {!isAdmin && !loading && !menu.length && null}
        </div>
      </div>
    </div>
  );
}

export default SideNav;
