import axios from 'axios';
import { URL } from './serverUrl';

const axiosInstance = axios.create({
    baseURL: URL,
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
    config => {
        // Get the token from localStorage
        const token = localStorage.getItem('aadmirals_token');
        // Attach the token as a Bearer token header
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    });

// Add a response interceptor
axiosInstance.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error.response.status === 401 && error.response.data.message === "Invalid Token" || error.response.data.message === "Unathorized user with having no token" ) {
            // Logout the user and clear localStorage
            logoutUser();
        }
        return Promise.reject(error);
    }
);

// Function to logout user and clear localStorage
function logoutUser() {
    localStorage.removeItem("aadmirals_token");
    localStorage.removeItem("dataId")
    localStorage.removeItem("role")
    window.location.href = '/login';
}

export default axiosInstance;
