import React from 'react'
import toast from 'react-hot-toast';
import {
    Button, Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import { URL } from '../../../config/serverUrl';
import axiosInstance from '../../../config/axiosInstance';
const DeleteConfirmation = ({ modal, toggle, isParent, serviceToDelete, setServiceToDelete, setIsParent, setServicesToRender, parent,setParent}) => {




    const handleDelete=async()=>{
       // toggle()
        if (isParent && serviceToDelete.child.length) {
            toast.error("Please delete sub-catagory first")
            return
        }
        try {
            if (isParent) {
                const result = await axiosInstance.delete(`${URL}/admin/delete-services-with-details/${serviceToDelete._id}`)
                setServicesToRender(pv => pv.filter(el => el._id !== serviceToDelete._id))
            }else{
                const result = await axiosInstance.delete(`${URL}/admin/delete-services-with-details/${serviceToDelete._id}`)

                setServicesToRender(pv => pv.map(el => {
                    if (el._id === parent._id) {
                        el.child = el.child.filter(ch => ch._id !== serviceToDelete._id);
                    }
                    return el;
                }));
            }
            toast.success("Service Deleted successfully")
        } catch (error) {
            toast.success("Something went wrong")
        }finally{
            setServiceToDelete(null)
            setIsParent(false)
            setParent(null)
            toggle()
        }



    }


  return (
      <Modal isOpen={modal} fade={false} toggle={toggle}>
          <ModalHeader toggle={toggle}>Warning!</ModalHeader>
          <ModalBody>
              Do you want delete this service.
          </ModalBody>
          <ModalFooter>
              <Button color="danger" onClick={

                handleDelete


                }>
                  Delete
              </Button>{' '}
              <Button color="secondary" onClick={()=>{
                setServiceToDelete(null)
                setIsParent(false)
                setParent(null)
                toggle()}}>
                  Cancel
              </Button>
          </ModalFooter>
      </Modal>
  )
}

export default DeleteConfirmation



