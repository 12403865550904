export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const NavLinks = [
    { link: '/', linkName: 'Dashboard' },
    { link: '/booking', linkName: 'Booking' },
    { link: '/news', linkName: 'News' },
    { link: '/refund', linkName: 'Refund' },
    { link: '/cities-we-serve', linkName: 'Cities we serve' },
    {
        linkName: 'Content Management',
        subLinks: [

            { link: '/services', linkName: 'Service' },
            { link: '/cities', linkName: 'Cities' },
            { link: '/home-page-view', linkName: 'Home' },
            { link: '/about-page-view', linkName: 'About' }
        ]
    },
    { link: '/fleet', linkName: 'Fleet' },
    { link: '/testimonials', linkName: 'Testimonials' },
    { link: '/contact-list', linkName: 'Contact List' },
    { link: '/contact-details', linkName: 'Contact Details' },
    { link: '/meet-crew', linkName: 'Meet Crew' },
    { link: '/our-services', linkName: 'Our Services' },
    { link: '/our-partners', linkName: 'Our Partners' },
    { link: '/faq', linkName: 'Faq' },
    { link: '/blog', linkName: 'Blog' },
    { link: '/users', linkName: 'Users' },
    { link: '/staff-members', linkName: 'Staff Members' },
    { link: '/ip-whitelist', linkName: 'IP White List' },
];

export function normalizeLinkName(linkName) {
    // Remove spaces and convert to lowercase
    return linkName.replace(/\s+/g, '').toLowerCase();
}