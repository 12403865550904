export const REQUEST = "REQUEST"
export const FETCH_ALL_BOOKING = "FETCH_ALL_BOOKING"
export const FETCH_ALL_BOOKING_ERROR = "FETCH_ALL_BOOKING_ERROR"
export const FETCH_COMPLETED_BOOKING = "FETCH_COMPLETED_BOOKING"
export const FETCH_COMPLETED_BOOKING_ERROR = "FETCH_COMPLETED_BOOKING_ERROR"
export const FETCH_CANCELLED_BOOKING = "FETCH_CANCELLED_BOOKING"
export const FETCH_CANCELLED_BOOKING_ERROR = "FETCH_CANCELLED_BOOKING_ERROR"
export const FETCH_IN_PROGRESS_BOOKING = "FETCH_IN_PROGRESS_BOOKING"
export const FETCH_IN_PROGRESS_BOOKING_ERROR = "FETCH_IN_PROGRESS_BOOKING_ERROR"
export const FETCH_SINGLE_BOOKING = "FETCH_SINGLE_BOOKING"
export const FETCH_SINGLE_BOOKING_ERROR = "FETCH_SINGLE_BOOKING_ERROR"
export const MARK_AS_COMPLETE = "MARK_AS_COMPLETE"
export const MARK_AS_CANCEL = "MARK_AS_CANCEL"
export const MARK_AS_COMPLETE_ERROR = "MARK_AS_COMPLETE_ERROR"
export const MARK_AS_CANCEL_ERROR = "MARK_AS_CANCEL_ERROR"
export const CLEAR_MESSAGES = "CLEAR_MESSAGES"
export const MARK_AS_COMPLETE_SUCCESS = "MARK_AS_COMPLETE_SUCCESS"
export const MARK_AS_CANCEL_SUCCESS = "MARK_AS_CANCEL_SUCCESS"
export const CLEAR_MARK_AS_COMPLETE_SUCCESS = "CLEAR_MARK_AS_COMPLETE_SUCCESS"
export const CLEAR_MARK_AS_CANCEL_SUCCESS = "CLEAR_MARK_AS_CANCEL_SUCCESS"