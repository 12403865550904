export const FaqsActionTypes = {
    FETCH_FAQ: "FETCH_FAQ",
    FETCH_FAQ_ERROR: "FETCH_FAQ_ERROR",
    DELETE_FAQ: "DELETE_FAQ",
    DELETE_FAQ_ERROR: "DELETE_FAQ_ERROR",
    ADD_FAQ: "ADD_FAQ",
    ADD_FAQ_ERROR: "ADD_FAQ_ERROR",
    UPDATE_FAQ: "UPDATE_FAQ",
    UPDATE_FAQ_ERROR: "UPDATE_FAQ_ERROR",
    CLEAR_ERROR: "CLEAR_ERROR",
    CLEAR_MESSAGE: "CLEAR_MESSAGE"
}