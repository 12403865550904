import React, { useState, useEffect } from 'react'
import AllBooking from './AllBookings/AllBookings'
import InProgress from './InProgress/InProgress'
import Completed from './Completed/Completed'
import Cancel from './Cancel/Cancel'
import { useDispatch, useSelector } from 'react-redux'
import {
  getAllBooking,
  getCompletedBooking,
  getCancelledBooking,
  markAsComplete,
  markAsCancel,
  clearMessages,
  resetMark,
  getInProgressBooking,
} from "../../redux/bookings/actions";
import styles from './Bookings.module.scss'
import toast from 'react-hot-toast'

const Bookings = () => {
    const dispatch = useDispatch()

    const [selectedOrders,setSelectedOrders]=useState([])
    const [action,setAction]=useState('')



    const booking = useSelector(state => state.booking)
    const { loading, data, updated, error, mark } = booking;

    useEffect(() => {
        dispatch(getAllBooking())
        dispatch(getCancelledBooking())
        dispatch(getCompletedBooking())
    }, [loading])


     useEffect(() => {
       if (mark) {
         toast.success(`Successfully,Marked as ${action}`);
         setTimeout(() => {
           dispatch(resetMark());
           dispatch(getAllBooking());
           dispatch(getCompletedBooking());
           dispatch(getCancelledBooking())
           dispatch(getInProgressBooking());
         }, 2000);
       }
     }, [mark]);


    const [allBookings, setAllBookings] = useState(true)
    const [inprogress, setInprogress] = useState(false)
    const [completed, setCompleted] = useState(false)
    const [cancel, setCancel] = useState(false)

    const handleClick = e => {

        setSelectedOrders([])

        if (e.currentTarget.dataset.id === '1') {
            setAllBookings(true)
            setInprogress(false)
            setCompleted(false)
            setCancel(false)
        } else if (e.currentTarget.dataset.id === '2') {
            setAllBookings(false)
            setInprogress(true)
            setCompleted(false)
            setCancel(false)
        } else if (e.currentTarget.dataset.id === '3') {
            setAllBookings(false)
            setInprogress(false)
            setCompleted(true)
            setCancel(false)
        } else if (e.currentTarget.dataset.id === '4') {
            setAllBookings(false)
            setInprogress(false)
            setCompleted(false)
            setCancel(true)
        }
    }

    let items = 144
    let title = allBookings ? 'All Booking' : inprogress ? 'In Progress' : completed ? 'Completed' : cancel ? 'Cancel' : null


    return (
      <section className="mainContainer">
        {/* TITLE */}
        <h1 className="title">{title}</h1>

        {/*** Tab Navigation ***/}

        <div className={styles.nav}>
          <div
            className={allBookings ? styles.activeNavItem : styles.navItem}
            data-id="1"
            onClick={handleClick}
          >
            <h6>All Booking</h6>
            <small>{data.all.bookings && data.all.bookings.length}</small>
          </div>

          <div
            className={inprogress ? styles.activeNavItem : styles.navItem}
            data-id="2"
            onClick={handleClick}
          >
            <h6>In Progress</h6>
            <small>
              {data.inProgress.bookings && data.inProgress.bookings.length}
            </small>
          </div>

          <div
            className={completed ? styles.activeNavItem : styles.navItem}
            data-id="3"
            onClick={handleClick}
          >
            <h6>Completed</h6>
            <small>
              {data.completed.bookings && data.completed.bookings.length}
            </small>
          </div>
          <div
            className={cancel ? styles.activeNavItem : styles.navItem}
            data-id="4"
            onClick={handleClick}
          >
            <h6>Cancel</h6>
            <small>
              {data.cancelled.bookings && data.cancelled.bookings.length}
            </small>
          </div>
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            margin: "10px 0",
            justifyContent:"end",
            gap:'10px'
          }}
        >

          <button
            disabled={!selectedOrders.length}
            style={{ alignSelf: "flex-end" }}
            type="button"
            class="btn btn-danger"
            onClick={() => 
              {
              setAction('canceled')
              dispatch(markAsCancel({id:selectedOrders}))
            
            }
            
            
            }
          >
            Mark As Cencel
          </button>

          <button
            disabled={!selectedOrders.length}
            style={{ alignSelf: "flex-end" }}
            type="button"
            class="btn btn-success"
            onClick={() => 
              {
              setAction('completed')
              dispatch(markAsComplete({id:selectedOrders}))
            
            }
            
            
            }
          >
            Mark As Complete
          </button>
        </div>

        {/* CARD */}
        <div className="sectionCard">
          <div className={styles.container}>
            {allBookings ? (
              <AllBooking
                setSelectedOrders={setSelectedOrders}
                selectedOrders={selectedOrders}
              />
            ) : inprogress ? (
              <InProgress
                setSelectedOrders={setSelectedOrders}
                selectedOrders={selectedOrders}
              />
            ) : completed ? (
              <Completed />
            ) : cancel ? (
              <Cancel />
            ) : null}
          </div>
        </div>
      </section>
    );
}

export default Bookings