export const OurPartnerActionTypes = {
    FETCH_OUR_PARTNER: "FETCH_OUR_PARTNER",
    FETCH_OUR_PARTNER_ERROR: "FETCH_OUR_PARTNER_ERROR",
    DELETE_OUR_PARTNER: "DELETE_OUR_PARTNER",
    DELETE_OUR_PARTNER_ERROR: "DELETE_OUR_PARTNER_ERROR",
    ADD_OUR_PARTNER: "ADD_OUR_PARTNER",
    ADD_OUR_PARTNER_ERROR: "ADD_OUR_PARTNER_ERROR",
    UPDATE_OUR_PARTNER: "UPDATE_OUR_PARTNER",
    UPDATE_OUR_PARTNER_ERROR: "UPDATE_OUR_PARTNER_ERROR",
    CLEAR_MESSAGE: "CLEAR_MESSAGE"
}