import React from "react";
import { StatusBtn } from "../StatusBtn/StatusBtn";
import Loader from "../Loader/Loader";
import styles from "./ReuseableTable.module.scss";
import { Link } from "react-router-dom";
const ReuseableTable = ({
  headings,
  data,
  setSelectedOrders , selectedOrders
}) => {

  const handleCheckboxChange = (itemId) => {

    const selectedIndex = selectedOrders.indexOf(itemId);
    if (selectedIndex === -1) {
      setSelectedOrders([...selectedOrders, itemId]);
    } else {
      const updatedSelectedItems = [...selectedOrders];
      updatedSelectedItems.splice(selectedIndex, 1);
      setSelectedOrders(updatedSelectedItems);
    }
  };

  return (
    <>
      {data ? (
        <table className={styles.table}>
          <thead>
            <tr className={styles.tableHeading}>
              {headings.map((heading) => (
                <th scope="col" key={heading}>
                  {heading}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((product) => (
              <tr key={product._id} className={styles.tableData}>
                <td
                  style={{
                    minWidth: "40px",
                    wordBreak: "keep-all",
                  }}
                  data-label="checkbox"
                >
                  <input
                    style={{
                      width: "20px",
                      height: "20px",
                      border: "1px solid #ee405e",
                    }}
                    disabled={product.status === 1 || product.status === -1}
                    class="form-check-input custom-checkbox"
                    type="checkbox"
                    id={product._id}
                    onChange={() => handleCheckboxChange(product._id)}
                    checked={selectedOrders?.includes(product._id)}
                  ></input>
                </td>
                {
                  <td data-label="name">
                    {product.accountDetails && product.accountDetails?.fullName
                      ? product.accountDetails.fullName
                      : product.accountDetails?.passangerName}
                  </td>
                }
                {
                  <td
                    style={{
                      maxWidth: "200px",
                      wordWrap: "break-word",
                    }}
                    data-label="email"
                  >
                    {product.accountDetails && product.accountDetails?.email
                      ? product.accountDetails.email
                      : product.accountDetails.passangerEmail}
                  </td>
                }
                {
                  <td data-label="book-number">
                    <Link to={`/detail/${product._id}`}>
                      {product.accountDetails && product.bookingId}
                    </Link>
                  </td>
                }
                {
                  <td data-label="car-name">
                    {product.accountDetails && product.car.name}
                  </td>
                }
                {
                  <td data-label="from">
                    {product.accountDetails && product.bookingType.from}
                  </td>
                }
                {
                  <td data-label="to">
                    {product.accountDetails && product.bookingType.to}
                  </td>
                }
                {
                  <td
                    style={{
                      minWidth: "100px",
                      wordBreak: "keep-all",
                    }}
                    data-label="date"
                  >
                    {product.accountDetails && product.bookingType.date}
                  </td>
                }
                {
                  <td
                    data-label="time"
                    style={{
                      minWidth: "80px",
                      wordBreak: "keep-all",
                    }}
                  >
                    {product.accountDetails && product.bookingType.time}
                  </td>
                }
                {
                  <td
                    style={{
                      minWidth: "80px",
                      wordBreak: "keep-all",
                    }}
                    data-label="price"
                  >
                    {product.accountDetails && product.amount}
                  </td>
                }
                <td
                  style={{
                    minWidth: "80px",
                    wordBreak: "keep-all",
                  }}
                  data-label="status"
                >
                  <StatusBtn
                    id={product._id}
                    booking={true}
                    refund={false}
                    name={
                      product.status === 1
                        ? "completed"
                        : product.status === 0
                        ? "in-progress"
                        : product.status === -1
                        ? "cancelled"
                        : null
                    }
                    color={
                      product.status === 1
                        ? "#2BCB20"
                        : product.status === 0
                        ? "#009EFB"
                        : product.status === -1
                        ? "#EE405E"
                        : null
                    }
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default ReuseableTable;
