import axios from 'axios';
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import {
    Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Input, Label
} from 'reactstrap';
import axiosInstance from '../../config/axiosInstance';
import { URL } from '../../config/serverUrl';
// import { URL } from '../../../config/serverUrl';
// import {sleep} from '../../../utils'
// import { toast } from 'react-hot-toast';
// import axiosInstance from '../../../config/axiosInstance';
const AddEditIPModal = ({ modal,
toggle,
setIPToEdit,
    IPToEdit, setAllIPS, edit,
setEdit } ) => {



    const [fields, setFields] = useState({
        ip: '',
        name: '',
    })
    useEffect(() => {
        if (edit && IPToEdit?.ip && IPToEdit?.name ) {
            setFields({
                ip: IPToEdit?.ip,
                name: IPToEdit?.name,
            })
        }
    }, [IPToEdit?.ip, IPToEdit?.name])
    const [errors, setErrors] = useState({
        ip: false,
        name: false,
    })

    useEffect(() => {
        if (!modal) {
            setFields({
                ip: '',
                name: '',
            })
        }
    }, [modal])

    const [loading, setLoading] = useState(false)
    const handleErrors = (e) => {
        const value = e.target.value
        const name = e.target.name
        if (!value) setErrors(pv => ({ ...pv, [name]: true }))
    }
    const ResetError = (e) => {
        const name = e.target.name
        setErrors(pv => ({ ...pv, [name]: false }))
    }
    const handleChange = (e) => {
        const value = e.target.value
        const name = e.target.name
        setFields(pv => ({
            ...pv,
            [name]: value
        }))
    }


    const addOrEditIP = async(e) => {
      e.preventDefault();
      try {
        setLoading(true)
          if (IPToEdit?._id){
              const res = await axiosInstance.post(`${URL}/admin/updateIPWhiteList`, {
                  name: fields.name,
                  ip: fields.ip,
                  id: IPToEdit?._id
              });
              console.log(res)
              setAllIPS((pv) => pv.map(el=>{
                  console.log(res.data.updatedData._id, el?._id)
                  console.log(res.data.updatedData._id === el?._id)
                  if (res.data.updatedData._id === el?._id){
                      return res.data.updatedData
                  }else{
                    return el
                  }

              }

              ));
              toast.success("IP updated successfully")

          }else{
              const res = await axiosInstance.post(`${URL}/admin/addIPToWhiteList`, {
                  name: fields.name,
                  ip: fields.ip
              });
              setAllIPS((pv) => [...pv, res.data.updatedData]);
              toast.success("IP added to whitelist successfully")
          }

      } catch (error) {
        console.log(error)
        toast.error("Something went wrong");
      } finally {
        setLoading(false);
        setIPToEdit(null)
        toggle()
        setErrors({
              ip: false,
              name: false,
          })
      }
    };


  return (
      <Modal isOpen={modal} toggle={toggle} >
          <ModalHeader toggle={toggle}>{`${IPToEdit?._id && edit ? "Edit" : 'Add'}  IP`}</ModalHeader>
          <ModalBody>
              <Form>
                  <FormGroup>
                      <Label for="ip">
                          IP
                      </Label>
                      <Input
                          onBlur={handleErrors}
                          onFocus={ResetError}
                          onChange={handleChange}
                          value={fields.ip}
                          id="ip"
                          name="ip"
                          placeholder="Enter IP"
                          type="text"
                      />
                      {errors?.ip && <div className="text-danger">
                          This is a required field
                      </div>}
                  </FormGroup>
                  <FormGroup>
                      <Label for="name">
                          Name
                      </Label>
                      <Input
                          onBlur={handleErrors}
                          onFocus={ResetError}
                          onChange={handleChange}
                          value={fields.name}
                          id="name"
                          name="name"
                          placeholder="Enter Name"
                          type="text"
                      />
                      {errors?.description && <div className="text-danger">
                          This is a required field
                      </div>}
                  </FormGroup>
              </Form>
          </ModalBody>
          <ModalFooter>
              <Button className='' color="primary" onClick={addOrEditIP}>
                 { loading && <> <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      <span class="visually-hidden">Loading...</span> </>}
                  { loading ?
                      (IPToEdit?._id ? "Updating":"Adding")
                      : (IPToEdit?._id ? "Update" : "Add")
                 }
              </Button>{' '}
              <Button color="secondary" onClick={()=>{
                  toggle()
                  setEdit(false)
                  setIPToEdit(null)
                  setErrors({
                      ip: false,
                      name: false,
})
              }}>
                  Cancel
              </Button>
          </ModalFooter>
      </Modal>
  )
}
export default AddEditIPModal