import React, { useState, useEffect } from "react";
import deleteBtn from "../../Assets/usersPage/delete.svg";
import { Button } from "../../Components/Button/Button";
import { CustomModal } from "../../Components/Modal/Modal";
import styles from "./ip.module.scss";
import Loader from "../../Components/Loader/Loader";
import FormData from "form-data";
import { Alert } from "reactstrap";
import axiosInstance from "../../config/axiosInstance";
import { URL } from "../../config/serverUrl";
import toast from "react-hot-toast";
import AddEditServiceModal from "./AddEditIPModal";
import AddEditIPModal from "./AddEditIPModal";

const IP = () => {


  const [allIPS, setAllIPS] = useState([]);
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [IPToEdit, setIPToEdit] = useState(null);
  const [edit, setEdit] = useState(false);


  const toggle = () => {

    setModal(!modal)};



  useEffect(() => {
     const getAllIPS=async()=>{
        setLoading(true)
        try {
          const res = await axiosInstance.get(`${URL}/admin/IPWhiteList`);
          console.log(res.data.IPList);
          setAllIPS(res.data.IPList);
        } catch (error) {
          toast.error('Something went wrong')
        }finally{
          setLoading(false)
        }
      }
      getAllIPS()
  }, []);


const handleDelete=async(ip)=>{
  try {
          const res = await axiosInstance.delete(
            `${URL}/admin//deleteIpFromWhiteList/${ip._id}`
          );

          setAllIPS(pv=>pv.filter(el=>el._id!==ip._id))

          toast.success("IP deleted from whilte list successfully")
        } catch (error) {
          toast.error('Something went wrong')
        }
  }






  // modal form submit





  return (
    <section className="mainContainer">
      {/* TITLE */}
      <h1 className="title">Add IP To white List</h1>
      {/* CARD */}
      {loading ? (
        <Loader />
      ) : (
        <div className="sectionCard">
          <div className={styles.container}>
            <div className={styles.addContainer}>
              <Button
                name="Add New IP"
                padding="14px 40px"
                margin="0px 0px"
                fontSize="14px"
                onClick={()=>{
                  toggle()
                  setEdit(false);
                  setIPToEdit(null);
                }}
              />
            </div>

            <div className={styles.fleetsContainer}>
             { allIPS.length &&
                allIPS.map((ip, index) => (
                  <div className={styles.fleet}>
                    {/* left section */}
                    <div className={styles.leftSection}>
                      <div className={styles.field}>
                        <label htmlFor="message">IP</label>
                        <p>{ip.ip}</p>
                      </div>
                      <div className={styles.field}>
                        <label htmlFor="name">Name</label>
                        <p>{ip.name}</p>
                      </div>
                    </div>

                    {/* buttons section */}
                    <div className={styles.btnSection}>
                      <Button
                        name="Update"
                        padding="5px 10px"
                        margin="0px 0px 10px 0px"
                        onClick={(e) => {
                          setEdit(true)
                          setIPToEdit(ip)
                          toggle()
                        }}
                      />
                      <img
                        src={deleteBtn}
                        onClick={(e) => {
                         handleDelete(ip)
                        }}
                        alt="delete-btn"
                      />
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      )}

      <AddEditIPModal
        modal={modal}
        toggle={toggle}
        setIPToEdit={setIPToEdit}
        IPToEdit={IPToEdit}
        setAllIPS={setAllIPS}
        edit={edit}
        setEdit={setEdit}
      />
    </section>
  );
};

export default IP;
