import React, { useEffect, useMemo, useState } from 'react'
import {
  Button, Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem, } from 'reactstrap';
import axiosInstance from '../../config/axiosInstance';
import { URL } from '../../config/serverUrl';
import axios from 'axios';
import { FaEdit } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import { FaFileInvoice } from "react-icons/fa";
import { AiFillPlusCircle } from "react-icons/ai";
import DeleteConfirmation from './Components/DeleteConfirmation';
import AddEditServiceModal from './Components/AddEditServiceModal';
import { NavLink, useHistory } from "react-router-dom";





const Services = () => {

  const [allservices,setAllservices]=useState([])
  const [servicesToRender,setServicesToRender]=useState([])
  const[isParent,setIsParent]=useState(false)
  const[edit,setEdit]=useState(false)
  const [open, setOpen] = useState('');
  const [modal, setModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [serviceToEdit, setServiceToEdit] = useState(null);
  const [parent, setParent] = useState(null);
  const [serviceToDelete,setServiceToDelete]=useState(null)

  const history=useHistory()




  const toggleConfirmationModal = () => setConfirmationModal(!confirmationModal);
  const toggle = () =>{
    if (modal){
      setIsParent(false)
      setEdit(false)
    }
    setModal(!modal)


  }

  const accordianToggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };
  useEffect(()=>{
    const getAllServices=async()=>{
      const res = await axios.get(`${URL}/admin/services`)
      console.log(res)
      setAllservices(res?.data?.servicesList)
    }
    getAllServices()
  },[])

  useEffect(()=>{
    console.log('i ran')
      const filteredArray = allservices.filter(obj => obj.type === 0 && obj.isParent);
      filteredArray.forEach(parentObj => {
        parentObj.child = allservices.filter(obj => obj?.parentId?._id === parentObj._id);
      });
      setServicesToRender(filteredArray) ;
  },[allservices.length])

//   const services = () => {
//     if (allservices && allservices.length) {
//       const filteredArray = allservices.filter(obj => obj.type === 0 && obj.isParent);
//       filteredArray.forEach(parentObj => {
//         parentObj.child = allservices.filter(obj => obj?.parentId?._id === parentObj._id);
//       });
//       return filteredArray;
//     }
//     return [];
//   }
//   const memoizedServices = useMemo(services, [allservices]);
// console.log(allservices)
//   console.log(memoizedServices)


 const handleSingleServiceUpdate=(id)=>{
   history.push(`/services/${id}`)
 }


  return (
    <div className="main_align_container font-semibold">
      <div className='d-flex flex-column gap-5'>



<div className='px-4 py-2 border border-secondary rounded ' style={{width:'100%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems: "center"}}>


        <div className='fw-bold  fs-4'>Services</div>


<div>


</div>

      <Button
      style={{
        marginLeft:'auto'
      }}
        className='service-btn'
      color="primary "
        outline onClick={
          ()=>{
            setIsParent(true)
            toggle()
          }
          }>
        Add Service Catagory
      </Button>







</div>

<div style={{width:'100%'}}>
        <Accordion flush open={open} toggle={accordianToggle}>

            {servicesToRender.length ? servicesToRender.map((el, index) => <AccordionItem  key ={el._id}>
              <div className='d-flex flex-column'>
                {!el.child.length && index === 0 && <div style={{ width: "100%", height: '0.5px', backgroundColor: '#DEE2E6' }}></div>}
            <div  className='d-flex flex-row gap-2 justify-content-between align-items-center'>
              {el.child.length ? <AccordionHeader style={{ width: '85%', }} targetId={el._id}>{el.pageName}</AccordionHeader> :


                  <p style={{ paddingLeft: "20px", marginTop: '16px' ,marginBottom:'16px'}}>{el.pageName} </p>


              }
              <div className='px-3' style={{ width: '15%',display:'flex',justifyContent:'space-between' }}>
                  <span>  <AiFillPlusCircle className='ui-icon' style={{ width: '24px', height: "24px" }} onClick={
                    () => {
                      setIsParent(false)
                      toggle()
                      setParent(el)
                    }} /></span>

                  <span><FaEdit onClick={
                    () => {
                      setIsParent(true)
                      setServiceToEdit(el)
                      setEdit(true)
                      toggle()
                    }} className='ui-icon' style={{width:'24px',height:"24px"}}/></span>
                  <span onClick={()=>handleSingleServiceUpdate(el._id)} ><  FaFileInvoice className='ui-icon' style={{width:'24px',height:"24px"}}/>


                  </span>
                  <span><AiFillDelete onClick={

                    ()=>{
                      setIsParent(true)
                      setServiceToDelete(el)
                      toggleConfirmationModal()
                    }


                    } className='ui-icon' style={{ width: '24px', height: "24px" }} /></span>

              </div>

            </div>
            {!el.child.length && index>0&& <div style={{ width: "100%", height: '0.5px', backgroundColor: '#DEE2E6' }}></div>}
            </div>
              {el.child.length ? <AccordionBody accordionId={el._id} style={{ backgroundColor: '#e2e2e2' }}>

               <div className='ps-4'>
                {
                    el.child.map(ch => <div key={ch._id}>

                      <div className='px-3' style={{width:'100%',alignItems:'center', display: 'flex', justifyContent: 'space-between' }}>

                        <div key={ch._id}>{ch.pageName}</div>

                        <div className='px-3' style={{ width: '15%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <span><FaEdit onClick={
                            () => {
                              setParent(el)
                              setIsParent(false)
                              setServiceToEdit(ch)
                              setEdit(true)
                              toggle()
                            }} className='ui-icon' style={{ width: '24px', height: "24px" }} /></span>
                          <span onClick={() => handleSingleServiceUpdate(ch._id)} ><  FaFileInvoice className='ui-icon' style={{ width: '24px', height: "24px" }} /></span>
                          <span><AiFillDelete onClick={() => {
                            setParent(el)
                            setIsParent(false)
                            setServiceToDelete(ch)
                            toggleConfirmationModal()
                          }} className='ui-icon' style={{ width: '24px', height: "24px" }} /></span>
                        </div>


                      </div>

                    <hr/>
                    </div>

                    )
                }
               </div>
              </AccordionBody>:null}
            </AccordionItem>):null}

        </Accordion>

</div>

      </div>

      <AddEditServiceModal modal={modal} toggle={toggle} isParent={isParent} edit={edit} setAllservices={setAllservices} serviceToEdit={serviceToEdit} parent={parent} setParent={setParent} setServiceToEdit={setServiceToEdit} setServicesToRender={setServicesToRender}/>
      <DeleteConfirmation modal={confirmationModal} toggle={toggleConfirmationModal} setModal={setConfirmationModal} isParent={isParent} serviceToDelete={serviceToDelete} setServiceToDelete={setServiceToDelete} setIsParent={setIsParent} setServicesToRender={setServicesToRender} parent={parent} setParent={setParent} />

      </div>
  )
}

export default Services