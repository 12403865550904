export const HomeActionTypes = {
    FETCH_HOME: "FETCH_HOME",
    FETCH_HOME_ERROR: "FETCH_HOME_ERROR",
    UPDATE_HOME: "UPDATE_HOME",
    UPDATE_HOME_ERROR: "UPDATE_HOME_ERROR",
    CLEAR_ERROR: "CLEAR_ERROR",
    CLEAR_MESSAGES: "CLEAR_MESSAGES",
    UPDATE_SERVICES_DETAIL: "UPDATE_SERVICES_DETAIL",
    GET_SERVICES_DETAIL: "GET_SERVICES_DETAIL",
    LOADING: "LOADING",
}