export const OurServicesActionTypes = {
    FETCH_OUR_SERVICES: "FETCH_OUR_SERVICES",
    FETCH_OUR_SERVICES_ERROR: "FETCH_OUR_SERVICES_ERROR",
    DELETE_OUR_SERVICES: "DELETE_OUR_SERVICES",
    DELETE_OUR_SERVICES_ERROR: "DELETE_OUR_SERVICES_ERROR",
    ADD_OUR_SERVICES: "ADD_OUR_SERVICES",
    ADD_OUR_SERVICES_ERROR: "ADD_OUR_SERVICES_ERROR",
    UPDATE_OUR_SERVICES: "UPDATE_OUR_SERVICES",
    UPDATE_OUR_SERVICES_ERROR: "UPDATE_OUR_SERVICES_ERROR",
    CLEAR_MESSAGE: "CLEAR_MESSAGE"
}