export const FleetActionTypes = {
    FETCH_FLEET: "FETCH_FLEET",
    FETCH_FLEET_ERROR: "FETCH_FLEET_ERROR",
    DELETE_FLEET: "DELETE_FLEET",
    DELETE_FLEET_ERROR: "DELETE_FLEET_ERROR",
    UPDATE_FLEET: "UPDATE_FLEET",
    UPDATE_FLEET_ERROR: "UPDATE_FLEET_ERROR",
    ADD_FLEET: "ADD_FLEET",
    ADD_FLEET_ERROR: "ADD_FLEET_ERROR",
    CLEAR_MESSAGE: "CLEAR_MESSAGE"
}