import axios from 'axios';
import React, { useEffect, useState } from 'react'
import {
    Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Input, Label
} from 'reactstrap';
import { URL } from '../../../config/serverUrl';
import {sleep} from '../../../utils'
import { toast } from 'react-hot-toast';
import axiosInstance from '../../../config/axiosInstance';
const AddEditServiceModal = ({ modal, toggle, isParent, edit, setAllservices, serviceToEdit = null, parent = null, setServiceToEdit, setParent, setServicesToRender } ) => {

    const [fields,setFields]=useState({
        pageName: '',
        description: '',
        displayName: '',
        displaySerial:'',
    })
    useEffect(()=>{
        if (edit && serviceToEdit){
            setFields({
                pageName: serviceToEdit?.pageName,
                description: serviceToEdit?.description ,
                displayName: serviceToEdit?.displayName ,
                displaySerial: serviceToEdit?.displaySerial
            })
        }
    }, [serviceToEdit?.pageName, serviceToEdit?.description, serviceToEdit?.displayName, serviceToEdit?.displaySerial])


    useEffect(() => {
        if (!modal) {
            setFields({
                pageName: '',
                description: '',
                displayName: '',
                displaySerial: '',
            })
        }
    }, [modal])
    const [errors,setErrors]=useState({
        pageName:false,
        description:false,
        displayName:false,
        displaySerial:false,
    })
    const [loading,setLoading]=useState(false)
    const handleErrors=(e)=>{
        const value = e.target.value
        const name = e.target.name
        if(!value)setErrors(pv => ({...pv,[name]: true}))
    }
    const ResetError =(e)=>{
    const name = e.target.name
     setErrors(pv => ({...pv,[name]: false}))
    }
    const handleChange=(e)=>{
        const value=e.target.value
        const name=e.target.name
        setFields(pv=>({
            ...pv,
            [name]:value
        }))
    }
    const createOrEditService =async()=>{
        try {
            setLoading(true)
           if(!edit)  {
            const result = await axiosInstance.post(`${URL}/admin/services`, {
                pageName: fields.pageName,
                description: fields.description,
                type: 0,
                displayName: fields.displayName,
                displaySerial: fields.displaySerial,
                ...(isParent ? { isParent } : {}),
                ...((!isParent && parent._id) ? { parentId: parent._id } : {})
                //   ...(parentId ? { parentId } : {}),
            });
                if(parent?._id){
                    setServicesToRender(pv => pv.map(el=>{
                        if(el._id===parent._id){
                            if(el?.child){
                                el.child = [...el.child, result?.data?.data]
                            }else{
                                el.child = [ result?.data?.data]
                            }
                        }
                        return el
                    }))
                }else{
                    setAllservices(pv => [...pv, result?.data?.data])
                }
        }
            else{
               const result = await axiosInstance.put(`${URL}/admin/services/${serviceToEdit?._id}`, {
               pageName: fields.pageName,
               description: fields.description,
               displayName: fields.displayName,
               displaySerial: fields.displaySerial,
               ...(isParent ? { isParent } : {}),})
               if(isParent){
                   setServicesToRender(pv=>pv.map(el=>{
                       if (el._id === result?.data?.data._id) {
                           return{
                            ...el,
                            description: result.data.data.description,
                            displayName: result.data.data.displayName,
                            displaySerial: result.data.data.displaySerial,
                            pageName: result.data.data.pageName,
                           }
                       }
                       else{
                           return el
                       }
                   }))
               }else{
                   setServicesToRender(pv => pv.map(el => {
                       if (el._id === parent._id) {
                           console.log('child', el.child)
                           el.child = el.child.map(ch => {
                               if (ch._id === result?.data?.data._id) {
                                   console.log('matched child', ch)
                                   return {
                                       ...ch,
                                       description: result.data.data.description,
                                       displayName: result.data.data.displayName,
                                       displaySerial: result.data.data.displaySerial,
                                       pageName: result.data.data.pageName,
                                   }
                               } else {
                                   return ch
                               }
                           })
                       }
                       console.log('element', el)
                       return el
                   }))
               }
            //    setAllservices(pv => pv.map(el =>{
            //        console.log(el._id === result?.data?.data._id)
            //     if (el._id === result?.data?.data._id){
            //         return result?.data?.data
            //     }
            //     return el
            // }))
            }
            toast.success(`Service ${edit?"edited":"created"} successfully.`);
            setFields({
                pageName: '',
                description: '',
                displayName: '',
                displaySerial: '',
            })
            await sleep(2000)
        } catch (error) {
            console.log(error)
            toast.error('Something went wrong.');
        }finally{
            setServiceToEdit(null)
            setParent(null)
            setLoading(false)
            toggle()
        }
    }
    // axios.post(`${URL}/admin/services`, sericesPage);
  return (
      <Modal isOpen={modal} toggle={toggle} fullscreen>
          <ModalHeader toggle={toggle}>{`${edit ? "Edit" : 'Add'} ${isParent ? "Catagory" : 'Sub Catagory'} `}</ModalHeader>
          <ModalBody>
              <Form>
                  <FormGroup>
                      <Label for="pageName">
                          Page Name/Slug
                      </Label>
                      <Input
                          onBlur={handleErrors}
                          onFocus={ResetError}
                          onChange={handleChange}
                          value={fields.pageName}
                          id="pageName"
                          name="pageName"
                          placeholder="Page Name"
                          type="text"
                      />
                      {errors?.pageName && <div className="text-danger">
                          This is a required field
                      </div>}
                  </FormGroup>
                  <FormGroup>
                      <Label for="pageDescription">
                          Page Description
                      </Label>
                      <Input
                          onBlur={handleErrors}
                          onFocus={ResetError}
                          onChange={handleChange}
                          value={fields.description}
                          id="pageDescription"
                          name="description"
                          placeholder="Page Description"
                          type="text"
                      />
                      {errors?.description && <div className="text-danger">
                          This is a required field
                      </div>}
                  </FormGroup>
                  <FormGroup>
                      <Label for="DisplayName">
                          Display Name
                      </Label>
                      <Input
                          onBlur={handleErrors}
                          onFocus={ResetError}
                          onChange={handleChange}
                          value={fields.displayName}
                          id="DisplayName"
                          name="displayName"
                          placeholder="Display Name"
                          type="text"
                      />
                      {errors?.displayName && <div className="text-danger">
                          This is a required field
                      </div>}
                  </FormGroup>
                  <FormGroup>
                      <Label for="DisplaySerial">
                          Display Serial
                      </Label>
                      <Input
                          onBlur={handleErrors}
                          onFocus={ResetError}
                          onChange={handleChange}
                          value={fields.displaySerial}
                          id="DisplaySerial"
                          name="displaySerial"
                          placeholder="Display Serial"
                          type="number"
                          min={1}
                      />
                      {errors?.displaySerial && <div className="text-danger">
                          This is a required field
                      </div>}
                  </FormGroup>
                 {(!isParent && edit)?
                      <FormGroup>
                          <Label for="ParentName">
                              Parent Name
                          </Label>
                          <Input
                              readOnly
                              value={parent?.pageName}
                              id="ParentName"
                              name="parentId"
                              placeholder="Parent Name"
                              type="text"
                          />
                      </FormGroup>
           :isParent?null:
                      <FormGroup>
                          <Label for="ParentName">
                              Parent Name
                          </Label>
                          <Input
                          readOnly
                            value={parent?.pageName}
                              id="ParentName"
                              name="parentId"
                              placeholder="Parent Name"
                              type="text"
                          />
                      </FormGroup>       }
              </Form>
          </ModalBody>
          <ModalFooter>
              <Button className='' color="primary" onClick={createOrEditService}>
                 { loading && <> <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      <span class="visually-hidden">Loading...</span> </>}
                  { loading ?
                  (edit ? "Updating":"Creating")
                      : (edit ? "Update" : "Create")
                 }
              </Button>{' '}
              <Button color="secondary" onClick={()=>{
                  setServiceToEdit(null)
                  setParent(null)
                  toggle()
                  setErrors({
                      pageName: false,
                      description: false,
                      displayName: false,
                      displaySerial: false,
})
              }}>
                  Cancel
              </Button>
          </ModalFooter>
      </Modal>
  )
}
export default AddEditServiceModal