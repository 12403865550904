import React, { useEffect, useState } from "react";
import { Route, Redirect, useLocation, useHistory } from "react-router-dom";
import { URL } from "../../config/serverUrl";
import toast from "react-hot-toast";
import axiosInstance from "../../config/axiosInstance";

const PrivateRoute = ({ component: Component, ...rest }) => {
  let [isAdmin, setIsAdmin] = useState(true);
  let [loading, setLoading] = useState(false);
  let [menu, setMenu] = useState([]);
  const location = useLocation().pathname;
  const history = useHistory();

  useEffect(async () => {
    setIsAdmin(true);
    setMenu([]);
    if (localStorage.getItem("role") == "Staff") {
      setLoading(true);
      try {
        let res = await axiosInstance.post(`${URL}/admin/admingetstaff`);
        let menuItems = res.data.data.menu;
        if (menuItems.includes("contentmanagement")) {
          setMenu([
            ...menuItems,
            "aboutpageview",
            "homepageview",
            "cities",
            "services",
          ]);
        } else {
          setMenu(menuItems);
        }
        setIsAdmin(false);
      } catch (error) {
        toast.error("Please re login");
      } finally {
        setLoading(false);
      }
    }

    // Checking location
  }, [location]);

  if (!isAdmin && !loading && menu.length) {
    let url = location.replace(/[\/-]/g, "").toLowerCase();
    if (url.includes("services")) {
      url = "services";
    }

    if (location != "/" && !menu.includes(url)) {
      history.push("/");
    }
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("aadmirals_token") ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default PrivateRoute;
