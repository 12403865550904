import React, { useEffect, useMemo, useState } from 'react'
import {
  Button, Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem, } from 'reactstrap';
import axiosInstance from '../../config/axiosInstance';
import { URL } from '../../config/serverUrl';
import axios from 'axios';
import { FaEdit } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import { FaFileInvoice } from "react-icons/fa";
import { AiFillPlusCircle } from "react-icons/ai";
import DeleteConfirmation from './Components/DeleteConfirmation';
import AddEditServiceModal from './Components/AddEditServiceModal';
import { NavLink, useHistory } from "react-router-dom";





const Cities = () => {

  const [allservices,setAllservices]=useState([])
  const [servicesToRender,setServicesToRender]=useState([])
  const[edit,setEdit]=useState(false)
  const [open, setOpen] = useState('');
  const [modal, setModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [serviceToEdit, setServiceToEdit] = useState(null);
  const [serviceToDelete,setServiceToDelete]=useState(null)

  const history=useHistory()




  const toggleConfirmationModal = () => setConfirmationModal(!confirmationModal);
  const toggle = () =>{
    if (modal){

      setEdit(false)
    }
    setModal(!modal)


  }

  const accordianToggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };
  useEffect(()=>{
    const getAllServices=async()=>{
      const res = await axios.get(`${URL}/admin/services`)
      console.log(res)
      setAllservices(res?.data?.servicesList)
    }
    getAllServices()
  },[])

  useEffect(()=>{
      const filteredArray = allservices.filter(obj => obj.type === 1);

      setServicesToRender(filteredArray) ;
  },[allservices.length])

 const handleSingleServiceUpdate=(id)=>{
   history.push(`/services/${id}`)
 }


  return (
    <div className="main_align_container font-semibold">
      <div className='d-flex flex-column gap-5'>



<div className='px-4 py-2 border border-secondary rounded ' style={{width:'100%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems: "center"}}>


        <div className='fw-bold  fs-4'>Cities</div>


<div>


</div>

      <Button
      style={{
        marginLeft:'auto'
      }}
        className='service-btn'
      color="primary "
        outline onClick={
          ()=>{
            toggle()
          }
          }>
        Add City
      </Button>







</div>

<div style={{width:'100%'}}>
        <div flush open={open} toggle={accordianToggle}>

            {servicesToRender.length && servicesToRender.map(el => <div className='d-flex flex-row gap-2 justify-content-between align-items-center'>
             <p style={{ paddingLeft: "20px" }}>{el.pageName} </p>
              <div className='px-3' style={{ width: '15%', display: 'flex', justifyContent: 'space-between' }}>

                <span><FaEdit onClick={
                  () => {
                    setServiceToEdit(el)
                    setEdit(true)
                    toggle()
                  }} className='ui-icon' style={{ width: '24px', height: "24px" }} /></span>
                <span onClick={() => handleSingleServiceUpdate(el._id)} ><  FaFileInvoice className='ui-icon' style={{ width: '24px', height: "24px" }} />


                </span>
                <span><AiFillDelete onClick={

                  () => {
                    setServiceToDelete(el)
                    toggleConfirmationModal()
                  }


                } className='ui-icon' style={{ width: '24px', height: "24px" }} /></span>

              </div>
            </div>



            )}





        </div>



</div>

      <AddEditServiceModal modal={modal} toggle={toggle}  edit={edit} setAllservices={setAllservices} serviceToEdit={serviceToEdit}  setServiceToEdit={setServiceToEdit} setServicesToRender={setServicesToRender}/>
      <DeleteConfirmation modal={confirmationModal} toggle={toggleConfirmationModal} setModal={setConfirmationModal}  serviceToDelete={serviceToDelete} setServiceToDelete={setServiceToDelete}  setServicesToRender={setServicesToRender} />

</div>
</div>
  )
}

export default Cities